/* Mixins */
.login-component {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  right: 10%;
  width: 405.2px;
  height: 476.6px;
  border-radius: 6px;
  background-color: white;
  opacity: 0.9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.login-component .ant-form {
  width: 320px;
  margin-top: 24px;
}
.login-component .ant-form input, .login-component .ant-form input:focus {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0);
  width: 250px;
}
.login-component .ant-form .ant-input-password {
  width: 250px;
}
.login-component .ant-form .ant-form-item {
  margin-top: 16px;
}
.login-component .ant-form .remember {
  margin: 10px 0 50px 0;
}
.login-component .ant-form .remembertext {
  color: #9D9E9F;
}
.login-component .ant-form .ant-divider-horizontal {
  margin: 0px;
}
.login-component .ant-form .ant-divider-vertical {
  margin: 0 15px;
  height: 20px;
}
.login-component .ant-form .has-border-bottom .ant-form-item-children {
  border-bottom: solid 1px #B7B8BC;
  padding-bottom: 10px;
}
.login-component .login-title {
  color: #33363C;
  font-size: 24px;
  font-weight: bolder;
  margin-top: 55px;
}
.login-component .login-btn .ant-btn-primary {
  width: 322px;
  height: 57px;
  font-size: 18px;
  font-weight: bold;
}